import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAdmins(ctx, queryParams){
      var data = JSON.stringify(queryParams);
      //var data = JSON.stringify(queryParams);
      // ชconsole.log('queryParams',data);
      return new Promise((resolve, reject) => {
          
          axios
          .post('/users/v1/lists', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
  
    },
    fetchDelete(ctx,id){
      return new Promise((resolve, reject) => {
        axios
          .delete('/users/v1/delete/'+id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchUsers(ctx, data){
      return new Promise((resolve, reject) => {
          axios
          .post('/users/v1/lists', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addUser(ctx, params){
      return new Promise((resolve, reject) => {
        axios
          .post(`/users/v1/add`, params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
    updateUser(ctx, data){
      return new Promise((resolve, reject) => {
        axios
          .post(`/users/v1/update/${data.id}`, data.data)
          .then(response => {resolve(response)})
          .catch(error => reject(error))
      })
    },
  },
}
