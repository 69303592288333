<template>
  <div>
    <b-card-actions action-collapse title="Report">  
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Sale Name"
            label-for="sale-name"
          >
            <v-select
              v-model="filter.staff_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="staffs"
              label="full_name"
              class="invoice-filter-select mr-1"
              placeholder="Select Staff"
              :reduce="(option) => option.id"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Search By"
            label-for="search-type"
          >
            <v-select
              v-model="filter.searchTypes"
              :options="searchTypes"
              label="search-type"
              placeholder="Select Type"
              :reduce="(option) => option"
              :clearable="false"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="3" v-if="filter.searchTypes === 'Month/Year'">
          <b-form-group
            label="Month"
            label-for="item-name"
          >
            <v-select
              v-model="filter.month"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="months"
              label="text"
              class="invoice-filter-select mr-1"
              placeholder="Select Month"
              :reduce="(option) => option.value"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="3" v-if="filter.searchTypes === 'Month/Year'">
          <b-form-group
            label="Year"
            label-for="item-name"
          >
            <b-form-input
              v-model="filter.year"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" v-if="filter.searchTypes !== 'Month/Year'">
          <b-form-group
            label="Start Date"
            label-for="start-date"
          >
            <b-form-datepicker 
              v-model="filter.startDate" 
              id="start-date" 
              class="mb-2"
              locale="en-GB" 
              :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
            >
            </b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="3" v-if="filter.searchTypes !== 'Month/Year'">
          <b-form-group
            label="End Date"
            label-for="end-date"
          >
            <b-form-datepicker 
              v-model="filter.endDate" 
              id="end-date" 
              class="mb-2"
              locale="en-GB" 
              :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
            >
            </b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
           
            @click="applyfilter"
          
            >
            
            <span>Apply Filter</span>
            </b-button>
           
            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="info"
            class="ml-1"
          v-if="filter.year && filter.month && filter.staff_id"
            @click="generateReport"
          
            >
            
            <span>Generate Report</span>
            </b-button>
            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="info"
            class="ml-1"
            v-else
            disabled
          
            >
            
            <span>Generate Report</span>
            </b-button>
            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="ml-1"
            @click="clearFilter"
          
            >
            
            <span>Clear</span>
            </b-button>

        </b-col>
       
      </b-row>
    </b-card-actions>
<b-card
    no-body
>
  <div class="m-2">
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
        <label>Entries</label>
        <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
        />
       
        </b-col>

        <!-- Search -->
        <b-col
        cols="12"
        md="6"
        >
        <div class="d-flex align-items-center justify-content-end">
            <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="Search..."
            />
            
        </div>
      </b-col>
    </b-row>
  </div>
  <b-table
    ref="refListTable"
    :items="fetchData"
    responsive
    striped
    :fields="tableColumns"
    primary-key="id"
    :sort-by.sync="sortBy"
    show-empty
    empty-text="No matching records found"
    :sort-desc.sync="isSortDirDesc"
    class="position-relative"
  >
    <template #cell(sale)="data">
      {{ data.item.sale_firstname }} {{ data.item.sale_lastname }}
    </template>
     <template #cell(price)="data">
      {{ data.item.price | getcomma }} 
    </template>
     <template #cell(created_at)="data">
     
      {{ data.item.created_at | formatDate }} 
    </template>
    <template #cell(date)="data">
     
      {{ data.item.date | formatDate }} 
    </template>
    
    <template #cell(start_date)="data">
     
     <span v-if="data.item.start_date"> {{ data.item.start_date | formatDate }} </span>
   </template>
    <template #cell(expire_date)="data">
     
      <span v-if="data.item.expire_date"> {{ data.item.expire_date | formatDate }} </span>
    </template>
    
    <template #cell(client)="data">
      {{ data.item.client_firstname }} {{ data.item.client_lastname }}
    </template>
     <template #cell(property)="data">
      
       <b-badge v-if="data.item.action == 'Sale'" variant="light-success">
          {{ data.item.action }}
        </b-badge>
        <b-badge v-if="data.item.action == 'Rent'" variant="light-info">
          {{ data.item.action }}
        </b-badge>
      {{ data.item.property_code }} 
      <small>{{ data.item.place_name }}</small>
      {{ data.item.units }}
      Floor {{ data.item.floors }}
    </template>
     <template #cell(owner)="data">
      {{ data.item.owner_firstname }} {{ data.item.owner_lastname }}
    </template>
    
     <template #cell(is_active)="data">

      <b-form-checkbox
          v-if="data.item.is_active == 1"
          checked="true"
          class="custom-control-primary"
          name="is_active"
          switch
        />
      <b-form-checkbox
          v-else
          checked="false"
          class="custom-control-primary"
          name="is_active"
          switch
        />
    </template>

    <template #cell(actions)="data">
      <span>
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-item :to="{ name: 'close-deal-update', params: { id: data.item.id } }"> 
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            <span>Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteconfirm(data.item.id)">
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span>Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </span>
        
    </template>
  </b-table>
  <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totals"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
     
      </table>
    </div>
</b-card>
<vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="false"
        margin="5"
        filename="report.pdf"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        :paginate-elements-by-height="2340"
      
        @hasDownloaded="attemptPrint($event)"
        @beforeDownload="beforeDownload($event)"
        @startPagination="startPagination($event)"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration($event)"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
        >
      <section slot="pdf-content" class="pdf-content  text-black p-1" v-if="setting_c.condition">
        <div class="mb-1 text-black" >{{ setting_c.staff_name }}</div>
        <table class="table table-report text-black" style="font-size: 9px;"  >
          <thead>
                <tr class="text-center">
                    <th style="font-size: 9px;" >No</th>
                    <th style="font-size: 9px;">Project</th>
                    <th style="font-size: 9px;">Unit No</th>
                    <th style="font-size: 9px;">Owner Name</th>
                    <th style="font-size: 9px;">Client Name</th>
                    <th style="font-size: 9px;">Period Start</th>
                    <th style="font-size: 9px;">Period End</th>
                    <th colspan="2"style="font-size: 9px;" >Fbr Commision</th>
                    <th style="font-size: 9px;">Incentive</th>
                    <th colspan="2" v-if="total_rent > 0" style="font-size: 9px;">Rent({{ setting_c.rent[0]?setting_c.rent[0].value:'' }}%)</th>
                    <th v-if="total_sale > 0" style="font-size: 9px;" >Sale({{ setting_c.sale[0]?setting_c.sale[0].value:'' }}%)</th>
                </tr>
                
            </thead>
            <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-center">Rent</td>
                  <td class="text-center">Sale</td>
                  <td></td>
                  <td  v-if="total_rent > 0" class="text-center">Get</td>
                  <td v-if="total_rent > 0" class="text-center" >total</td>
                  <td class="text-center"   v-if="total_sale > 0">Get</td>
                </tr>
                <tr  v-for="(item,i) in setting_c.items" :key="'print-'+i"> 
                  <td>{{ i+1 }}</td>
                  <td>{{ item.place_name }}</td>
                  <td>{{ item.units }}</td>
                  <td>  {{ item.owner_firstname }} {{ item.owner_lastname }}</td>
                  <td> {{ item.client_firstname }} {{ item.client_lastname }}</td>
                  <td> <span v-if="item.start_date"> {{ item.start_date | formatDate }} </span></td>
                  <td><span v-if="item.expire_date"> {{ item.expire_date | formatDate }} </span></td>
                  <td class="text-center"><span v-if="item.action=='Rent'">{{ item.commission | getcomma}}</span></td>
                  <td class="text-center"><span v-if="item.action=='Sale'">{{ item.commission | getcomma}}</span></td>
                  <td class="text-center">{{ item.incentive }}</td>
                  <td class="text-center" v-if="total_rent > 0"><span v-if="item.action=='Rent' && item.commission > 0 && setting_c.rent[0]">{{ (item.commission*(setting_c.rent[0].value/100)) | getcomma  }}</span></td>
                  <td class="text-center" v-if="total_rent > 0"><span v-if="item.action=='Rent'">{{ ((item.commission*(setting_c.rent[0].value/100))+item.incentive) | getcomma  }}</span></td>
                  <td class="text-center" v-if="total_sale > 0" ><span v-if="item.action=='Sale' && item.commission > 0 && setting_c.sale[0]">{{ (item.commission*(setting_c.sale[0].value/100)) | getcomma  }}</span></td>

                </tr>
                <tr>
                  <td colspan="7" class="text-right">Total</td>
                  <td>{{ total_rent | getcomma }}</td>
                  <td>{{ total_sale | getcomma }}</td>
                  <td class="text-center">
                    <span v-if="setting_c.incentive_totals > 0 ">
                      {{ setting_c.incentive_totals | getcomma  }}
                    </span>
                  </td>
                  <td>
                    <span v-if="total_rent > 0 && setting_c.rent[0]">
                      {{ setting_c.condition.total_c_rent | getcomma  }}
                    </span>
                  </td>
                  
                  <td>
                    <span v-if="total_rent > 0 && setting_c.rent[0]">
                      {{ setting_c.condition.total_c_rent_ic | getcomma  }}
                    </span>
                  </td>
                  <td>
                    <span v-if="total_sale > 0 && setting_c.sale[0]">
                      {{ setting_c.condition.total_c_sale | getcomma  }}
                    </span>
                  </td>
                </tr>
                <tr v-if="setting_c.condition.commsions_tax > 0">
                  <td colspan="9"></td>
                  <td width="100px">
                    <span v-if="setting_c.condition.commsions_tax > 0">หัก ณ ที่จ่าย {{ setting_c.condition.commsions_tax }}%</span>
                  </td>
                  <td>
                    <span v-if="setting_c.condition.commsions_tax > 0 && total_rent > 0">{{ setting_c.condition.total_ct_rent.toFixed(2) | getcomma  }}</span>
                    <span v-else-if="total_rent > 0">{{  total_rent.toFixed(2) | getcomma  }}</span>
                  </td>
                  <td>
                    <span v-if="setting_c.condition.commsions_tax > 0 && total_rent > 0"><b>{{ setting_c.condition.total_ct_rent_ic.toFixed(2) | getcomma  }}</b></span>
                    <span v-else-if="total_rent > 0"><b>{{  setting_c.condition.total_c_rent_ic.toFixed(2) | getcomma  }}</b></span>
                  </td>
                  <td>
                    <span v-if="setting_c.condition.commsions_tax > 0 && total_sale > 0"><b>{{ setting_c.condition.total_ct_sale.toFixed(2) | getcomma  }}</b></span>
                    <span v-else-if="total_sale > 0"><b>{{ total_sale.toFixed(2) | getcomma  }}</b></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="10"></td>
                  
                  <td>
                    <span v-if="total_rent > 0">{{ setting_c.condition.total_np_rent.toFixed(2) | getcomma  }}</span>
                   
                  </td>
                  <td>
                   
                    <span v-if="total_rent > 0"><b>{{  setting_c.condition.total_np_rent_ic.toFixed(2) | getcomma  }}</b></span>
                  </td>
                  <td>
                    
                    <span v-if="total_sale > 0"><b>{{  setting_c.condition.total_np_sale.toFixed(2) | getcomma  }}</b></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="text-center" style="border-left:1px solid #ebe9f1;"><b>Basic Salary</b></td>
                  <td style="border-left:1px solid #ebe9f1;">{{ setting_c.condition.salary | getcomma }}</td>
                  <td class="text-right" style="border-left:1px solid #ebe9f1;">{{ setting_c.condition.diff | getcomma }}</td>
                  <td class="text-right" style="border-left:1px solid #ebe9f1;border-right:1px solid #ebe9f1;">{{ setting_c.condition.salary_sum | getcomma }}</td>
                </tr>
                <tr>
                  <td class="text-center" style="border-left:1px solid #ebe9f1;" >Rent</td>
                  <td class="text-center" style="border-left:1px solid #ebe9f1;"><b>Com**</b></td>
                  <td style="border-left:1px solid #ebe9f1;">{{  total_rent | getcomma }}</td>
                  <td class="text-right" style="border-left:1px solid #ebe9f1;"><span  v-if="total_rent > 0">{{ setting_c.condition.total_ct_rent_ic.toFixed(2) | getcomma  }}</span></td>
                  <td class="text-right" style="border-left:1px solid #ebe9f1;border-right:1px solid #ebe9f1;"> 
                    <span v-if="total_rent > 0">{{  setting_c.condition.total_np_rent_ic.toFixed(2) | getcomma  }}</span>
                   
                  </td>
                </tr>
                <tr>
                  <td style="border-left:1px solid #ebe9f1;" class="text-center" >Sale</td>
                  <td style="border-left:1px solid #ebe9f1;" class="text-center"><b>Com**</b></td>
                  <td style="border-left:1px solid #ebe9f1;">{{  total_sale | getcomma }}</td>
                  <td class="text-right"  style="border-left:1px solid #ebe9f1;"><span v-if="total_sale > 0"> {{ setting_c.condition.total_c_sale | getcomma  }}</span></td>
                  <td class="text-right" style="border-left:1px solid #ebe9f1;border-right:1px solid #ebe9f1;"><span v-if="total_sale > 0"> {{  setting_c.condition.total_np_sale.toFixed(2) | getcomma  }}</span></td>
                </tr>
                <tr>
                  <td colspan="4"  style="border-bottom:1px solid #ebe9f1;border-left:1px solid #ebe9f1;" class="text-center"><b>Total Salary</b></td>
                  <td class="text-right" style="border-bottom:1px solid #ebe9f1;border-left:1px solid #ebe9f1;border-right:1px solid #ebe9f1;">
                    <b v-if="setting_c.condition.netpayment">{{  setting_c.condition.netpayment.toFixed(2) | getcomma  }}</b>
                  </td>
                </tr>

            </tbody>
        </table>
      </section>
  </vue-html2pdf>
</div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BImg,BFormSelect,BFormGroup,
  BFormDatepicker,
} from 'bootstrap-vue'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import { ref,onUnmounted } from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import useCloseDealList from './useCloseDealList'
import closedealStoreModule from '../closedealStoreModule'
import Ripple from 'vue-ripple-directive'
import adminStoreModule from '@/views/users/admin/adminStoreModule'
import VueHtml2pdf from 'vue-html2pdf'
export default {
  components: {
     BCard,
        BRow,
        BCol,
        BFormInput,
        BFormCheckbox,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTooltip,
        BImg,
        vSelect,
        BFormSelect,
        BCardActions,
        BFormGroup,
        VueHtml2pdf,
        BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  setup() {
    const ADMIN_APP_STORE_MODULE_NAME = 'app-admin'

// Register module
    if (!store.hasModule(ADMIN_APP_STORE_MODULE_NAME)) store.registerModule(ADMIN_APP_STORE_MODULE_NAME, adminStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ADMIN_APP_STORE_MODULE_NAME)) store.unregisterModule(ADMIN_APP_STORE_MODULE_NAME)
    });
    const CLOSE_APP_STORE_MODULE_NAME = 'app-close-deal'
    if (!store.hasModule(CLOSE_APP_STORE_MODULE_NAME)) store.registerModule(CLOSE_APP_STORE_MODULE_NAME, closedealStoreModule)
    const imgPath = store.state.appConfig.layout.imgPath
   
    // UnRegister on leave
    onUnmounted(() => {
    
        if (store.hasModule(CLOSE_APP_STORE_MODULE_NAME)) store.unregisterModule(CLOSE_APP_STORE_MODULE_NAME)
    })
    const {
        fetchData,
      
      tableColumns,
      perPage,
      currentPage,
      totals,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
     deleteData,
      refetchData,
      staffs,
      filter,
      months,
      applyfilter,
      clearFilter,
      getPrintData,
      items,
      total_rent,
      total_sale,
      setting_c,
      getSettingData

        } = useCloseDealList()
        return {
         fetchData,
      
      tableColumns,
      perPage,
      currentPage,
      totals,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
     deleteData,
      refetchData,
      staffs,
      filter,
      months,
      applyfilter,
      clearFilter,
      getPrintData,
      items,
      total_rent,
      total_sale,
      setting_c,
      getSettingData
        }
  },
  filters: {
    /*getRentValue: function(total,setting){
      let rent_c =     setting.rents.filter((value)=>{
            
            if(value.cond == 'less_than'){
              if(total < (value.commission*1)){
                return value;
              }
              
            }else if(value.cond == 'less_than_equal'){
              if(total <= (value.commission*1)){
                return value;
              }
            }else if(value.cond == 'equal_to'){
              if(total  == (value.commission*1)){
                return value;
              }
            }else if(value.cond == 'more_than'){
              if(total > (value.commission*1)){
                return value;
              }
            }else if(value.cond == 'more_than_equal'){
              if(total >= (value.commission*1)){
                return value;
              }
      
  
            }
            
        });
      
      if(rent_c[0]){
        return rent_c[0].value;

      }else{
         return 0;
      }

    },
  
    getSaleValue: function(total,setting){
      let rent_c =     setting.sales.filter((value)=>{
            
            if(value.cond == 'less_than'){
              if(total < (value.commission*1)){
                return value;
              }
              
            }else if(value.cond == 'less_than_equal'){
              if(total <= (value.commission*1)){
                return value;
              }
            }else if(value.cond == 'equal_to'){
              if(total  == (value.commission*1)){
                return value;
              }
            }else if(value.cond == 'more_than'){
              if(total > (value.commission*1)){
                return value;
              }
            }else if(value.cond == 'more_than_equal'){
              if(total >= (value.commission*1)){
                return value;
              }
      
  
            }
            
        });
      
      if(rent_c[0]){
        return rent_c[0].value;

      }else{
         return 0;
      }

    },*/
    getcomma: function(text){
      if(!text){
        return ''

      }
			 var nStr = text;
			nStr += '';
			var x = nStr.split('.');
			var x1 = x[0];
			var x2 = x.length > 1 ? '.' + x[1] : '';
			var rgx = /(\d+)(\d{3})/;
			while (rgx.test(x1)) {
				x1 = x1.replace(rgx, '$1' + ',' + '$2');
			}
			return x1+x2 ;
		},
     formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    },
  },
   methods:{
    async generateReport () {
    //  await this.getSettingData();
       await  this.getPrintData();
      // await this.getSettingData();
        
           console.log('response-p',this.setting_c);
         
          
            this.$refs.html2Pdf.generatePdf();
            
          
        },
       async onProgress(event){
            console.log('onProgress',event);
           // console.log('event', event);
        },
        async hasStartedGeneration(){
            console.log('hasStartedGeneration');
           
        },
        async startPagination(event){
            console.log('startPagination',event);
            

        },
        async hasGenerated(){
            console.log('hasGenerated');
        },
       
        attemptPrint(blob) {
            console.log('attemptPrint',blob);
       // let url = URL.createObjectURL(blob);
        //window.open(url); //opens the pdf in a new tab
      },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            console.log('beforeDownload',options);
            options.margin = [0.7, 0.1, 0.7, 0.1];
           
          //  console.log('options',options);
        //    console.log('pdfContent',pdfContent);
            
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                var dt = new Date();
                var year  = dt.getFullYear();
                var month = (dt.getMonth() + 1).toString().padStart(2, "0");
                var day   = dt.getDate().toString().padStart(2, "0");
                let date = day+'/'+month+'/'+year
                const totalPages = pdf.internal.getNumberOfPages()
                const monthIndex = this.months.findIndex(p => p.value === this.filter.month)

                function formatDate(dateString) {
                  const date = new Date(dateString);
                  const day = String(date.getDate()).padStart(2, '0');
                  const month = String(date.getMonth() + 1).padStart(2, '0');
                  const year = date.getFullYear();
                  return `${day}/${month}/${year}`;
                }

                const formattedStartDate = formatDate(this.filter.startDate);
                const formattedEndDate = formatDate(this.filter.endDate);

                var text = this.filter.searchTypes === 'Month/Year'? "Salary Summary in "+this.months[monthIndex].text+" "+this.filter.year: `Salary Summary between ${formattedStartDate} and ${formattedEndDate}`;
               // var xOffset = (pdf.internal.pageSize.getWidth() * 0.88) - (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / 2);
                var xOffset = (pdf.internal.pageSize.getWidth()/2);
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setFontSize(14)
                    //pdf.fromHTML('<div>test</div>');
                   
                    pdf.setTextColor('#000000')
                    pdf.text(text, xOffset, (pdf.internal.pageSize.getHeight() - (pdf.internal.pageSize.getHeight()-0.5)),{align: 'center'})
                    pdf.text(date, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - (pdf.internal.pageSize.getHeight()-0.5)))
                    pdf.line(10,10,(pdf.internal.pageSize.getWidth() * 0.88),20,'S')
                    pdf.setPage(i)
                    pdf.setFontSize(10)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                    
                } 
              
            }).output('bloburl').then(r => { window.open(r) });
           
         // this.$refs.html2Pdf.generatePdf()
        },
    deleteconfirm(id){
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Close Deal ID.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
            this.deleteData(id)
          }
          console.log('value',value);
          //this.deleteData(id)
        })
      
    }
  },
  data() {
    return {
      searchTypes: ['Month/Year', 'Period'],
    }
  }
}
</script>
