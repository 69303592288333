import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useCloseDealList() {
    const toast = useToast()
    const refListTable = ref(null);
    const staffs = ref([]);
    const tableColumns = [
        { key: 'id', label: '#', sortable: true },
        { key: 'sale',label:'Sale', sortable: false },
        { key: 'code',label: 'Close Deal', sortable: false },
        { key: 'eq_code',label:'Enquiry', sortable: false },
        { key: 'date',label:'Date', sortable: true },
        { key: 'property',label:'Property', sortable: false },
        { key: 'owner',label:'Owner', sortable: false },
        { key: 'client',label:'Client', sortable: false },
        
        { key: 'price',label:'Price', sortable: false },
        { key: 'commission',label:'Commission', sortable: false },
        { key: 'start_date',label:'Commencing', sortable: false },
        { key: 'expire_date',label:'Terminating', sortable: false },
       
        
        { key: 'remark',label:'Remark', sortable: false },
        
        { key: 'actions' },
        
    ]
    const today = new Date();
    const filter = ref({
      year:today.getFullYear(),
      month:today.getMonth()+1,
      staff_id:'',
      searchTypes: 'Month/Year',
      startDate: '',
      endDate: ''
    });
    const months = ref([
      {
        value:1,
        text:"January"
      },
      {
        value:2,
        text:"Febuary"
      },
      {
        value:3,
        text:"March"
      },
      {
        value:4,
        text:"April"
      },
      {
        value:5,
        text:"May"
      },
      {
        value:6,
        text:"June"
      },
      {
        value:7,
        text:"July"
      },
      {
        value:8,
        text:"August"
      },
      {
        value:9,
        text:"September"
      },
      {
        value:10,
        text:"October"
      },
      {
        value:11,
        text:"November"
      },
      {
        value:12,
        text:"December"
      },
    ])

    const perPage = ref(10)
    const totals = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const users = JSON.parse(localStorage.getItem('userData'));
    const user = ref(JSON.parse(localStorage.getItem('userData')));
    const created_by = ref('');
    const month = ref('');
    const year = ref('');
    const searchTypes = ref('')
    const startDate = ref('')
    const endDate = ref('')
    const items = ref([]);
    const total_rent = ref(0);
    const total_sale = ref(0);
    const setting_c = ref({
      condition:{},
      items:[]
    });
    const permission_as = user.value.ability.findIndex((s => s.action === 'all' && s.subject === 'CloseDeal' || (s.action === 'manage' && s.subject === 'all')));
    if(permission_as < 0){
      created_by.value = user.value.id;
      filter.value.staff_id= user.value.id;

    }
          
    const fetchAdmins = async()=>{
        const staff =   await store
        .dispatch('app-admin/fetchAdmins', {
          
            perpage:100,
            offset:0,
            groupType: 'admin'
        })
        if(staff.data.success){
           
            const permission_a = user.value.ability.findIndex((s => s.action === 'all' && s.subject === 'CloseDeal' || (s.action === 'manage' && s.subject === 'all')));
          
            if(permission_a < 0){
                staffs.value = staff.data.data.filter((value)=>{
                    if(value.id == user.value.id){
                        return value;
                    }

                });
            }else{
                staffs.value = staff.data.data;
            }
            
            console.log('staffs',staffs.value);
        }
      
    
    };
    fetchAdmins();
    const getSettingData = async()=>{
      const respa =   await store.dispatch('app-close-deal/getSetting',filter.value.staff_id);
      if(respa.data.data){
        let setting = respa.data.data;
        setting_c.value = setting;

      }
     
    }
    const getPrintData = async()=>{
      const response =  await  store.dispatch('app-close-deal/fetchPrintData', {
       
       
  
        created_by:filter.value.staff_id,
        month:filter.value.month,
        year:filter.value.year,
      });
     
      const Index = staffs.value.findIndex(p => p.id === filter.value.staff_id);
     
        setting_c.value.staff_name = staffs.value[Index].full_name;
      if(response.data.data){
        setting_c.value.items = response.data.data.items;
        setting_c.value.condition = response.data.data.setting;
        setting_c.value.rent = response.data.data.setting.rent;
        setting_c.value.incentive_totals =  response.data.data.incentive_totals*1;
        setting_c.value.sale = response.data.data.setting.sale;
        total_rent.value = response.data.data.rent_totals*1;
        total_sale.value = response.data.data.sale_totals*1;

      }
      

    }
    const getPrintData_back = async()=>{
     
     
      const response =  await  store.dispatch('app-close-deal/fetchData', {
       
       
  
        created_by:filter.value.staff_id,
        month:filter.value.month,
        year:filter.value.year,
        searchTypes: filter.searchTypes,
        startDate: filter.startDate,
        endDate: filter.endDate
      });
    
     
      
      setting_c.value.items = response.data.data;
        console.log('items-a',response.data.data);
        total_rent.value =  0;
        total_sale.value =  0;
        if(setting_c.value.items.length > 0){
          setting_c.value.items.map((item)=>{
            if(item.action == 'Rent'){
              total_rent.value += item.commission*1;
            }
            if(item.action == 'Sale'){
              total_sale.value += item.commission*1;
  
            }
  
          });
        }
        const respa =   await store.dispatch('app-close-deal/getSetting',filter.value.staff_id);
       // const adds = await Promise.all(respa.data.data);
        
        const setting_s = respa.data.data;
        setting_c.value.condition = setting_s;

        let rent_c =  await   setting_c.value.condition.rents.filter((value)=>{
            
          if(value.cond == 'less_than'){
            if(total_rent.value < (value.commission*1)){
              return value;
            }
            
          }else if(value.cond == 'less_than_equal'){
            if(total_rent.value <= (value.commission*1)){
              return value;
            }
          }else if(value.cond == 'equal_to'){
            if(total_rent.value  == (value.commission*1)){
              return value;
            }
          }else if(value.cond == 'more_than'){
            if(total_rent.value > (value.commission*1)){
              return value;
            }
          }else if(value.cond == 'more_than_equal'){
            if(total_rent.value >= (value.commission*1)){
              return value;
            }
    

          }
          
      });
      setting_c.value.rent = rent_c[0];
      let sale_c =   await  setting_c.value.condition.sales.filter((value)=>{
            
        if(value.cond == 'less_than'){
          if(total_rent.value < (value.commission*1)){
            return value;
          }
          
        }else if(value.cond == 'less_than_equal'){
          if(total_rent.value <= (value.commission*1)){
            return value;
          }
        }else if(value.cond == 'equal_to'){
          if(total_rent.value  == (value.commission*1)){
            return value;
          }
        }else if(value.cond == 'more_than'){
          if(total_rent.value > (value.commission*1)){
            return value;
          }
        }else if(value.cond == 'more_than_equal'){
          if(total_rent.value >= (value.commission*1)){
            return value;
          }
  

        }
        
    });
    setting_c.value.sale = sale_c[0];
        
      /*if( resAdd){
        
        if(setting.rents.length > 0){
        
            let rent_c =  await Promise.all(setting.rents.filter((value)=>{
            
                if(value.cond == 'less_than'){
                  if(total_rent.value < (value.commission*1)){
                    return value;
                  }
                  
                }else if(value.cond == 'less_than_equal'){
                  if(total_rent.value <= (value.commission*1)){
                    return value;
                  }
                }else if(value.cond == 'equal_to'){
                  if(total_rent.value  == (value.commission*1)){
                    return value;
                  }
                }else if(value.cond == 'more_than'){
                  if(total_rent.value > (value.commission*1)){
                    return value;
                  }
                }else if(value.cond == 'more_than_equal'){
                  if(total_rent.value >= (value.commission*1)){
                    return value;
                  }
          

                }
                
            }));
            setting.value.rent = rent_c[0];
            console.log('rent_c',rent_c);
            let sale_c =  await Promise.all(setting.sales.filter((value)=>{
             
                if(value.cond == 'less_than'){
                  if(total_sale.value < (value.commission*1)){
                    return value;
                  }
                  
                }else if(value.cond == 'less_than_equal'){
                  if(total_sale.value <= (value.commission*1)){
                    return value;
                  }
                }else if(value.cond == 'equal_to'){
                  if(total_sale.value  == (value.commission*1)){
                    return value;
                  }
                }else if(value.cond == 'more_than'){
                  if(total_sale.value > (value.commission*1)){
                    return value;
                  }
                }else if(value.cond == 'more_than_equal'){
                  if(total_sale.value >= (value.commission*1)){
                    return value;
                  }
          

                }
                
            }));
            setting.value.sale = sale_c[0];
           

        }
      

      }*/
      
    }
    
    //1,6,7,8,9

    const applyfilter = async()=>{
      created_by.value = filter.value.staff_id;
      month.value = filter.value.month;
      year.value = filter.value.year;
      searchTypes.value = filter.value.searchTypes,
      startDate.value = filter.value.startDate,
      endDate.value = filter.value.endDate

      refetchData();

    }
    const clearFilter = async()=>{
      created_by.value = '';
      month.value = '';
      year.value = '';
      filter.value = {
        year:'',
        month:'',
        staff_id:'',
        searchTypes: 'Month/Year',
        startDate: '',
        endDate: ''
      }
      refetchData();

    }
   
    const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totals.value,
        }
    })
    const refetchData = () => {
    
        refListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery], () => {
        console.log('change');
        refetchData()
    })
    const fetchData = (ctx, callback)=>{
      const offset = perPage.value*(currentPage.value-1);
      let user_id = ''
      if(users.type_id == '8' || users.type_id == '9'){
        user_id = users.id;
      }
      //user_id = users.id;
        store
        .dispatch('app-close-deal/fetchData', {
          perpage: perPage.value,
          offset:offset,
          code:searchQuery.value,
          type_id:users.type_id,
          created_by:created_by.value,
          month:month.value,
          year:year.value,
          searchTypes: searchTypes.value,
          startDate: startDate.value,
          endDate: endDate.value
        })
        .then(response => {
          
       
          const closedeals = response.data.data;
       //   console.log('closedeals',closedeals);
          //totals
          totals.value = response.data.total
        //  const { invoices, total } = response.data
         callback(closedeals)
         // callback(invoices)
          //totalInvoices.value = total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching invoices' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        
      }
      const deleteData = (id) => {
        //fetchDeleteProperty
        store.dispatch('app-close-deal/fetchDelete',id).then(response=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Delete Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData();
        }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Delete Close Deal",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        //console.log('id', id);
        
      }
    return {
      fetchData,
      
      tableColumns,
      perPage,
      currentPage,
      totals,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      deleteData,
      refetchData,
      staffs,
      filter,
      months,
      applyfilter,
      clearFilter,
      getPrintData,
      items,
      total_rent,
      total_sale,
      setting_c,
      getSettingData
    }
}