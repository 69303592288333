import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx, queryParams){

            var data = JSON.stringify(queryParams);
      
            return new Promise((resolve, reject) => {
                
                axios
                .post('/close-deal/v1/lists', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        
        },
        fetchPrintData(ctx, queryParams){
            var data = JSON.stringify(queryParams);
      
            return new Promise((resolve, reject) => {
                
                axios
                .post('/close-deal/v1/listsprint', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        updateSetting(ctx, queryParams){
            
            var data = JSON.stringify(queryParams);
            console.log('queryParams',data);
            return new Promise((resolve, reject) => {
                
                axios
                .post('/close-deal/v1/update-setting', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        
        },
        getSetting(ctx, id){
            
      
            return new Promise((resolve, reject) => {
                
                axios
                .get('/close-deal/v1/get-setting/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },

        fetchDelete(ctx, id){
            return new Promise((resolve, reject) => {
                axios
                  .delete('/close-deal/v1/delete/'+id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })
        }
        
    }
}